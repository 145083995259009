export const ProgramsTitles = {
  id: "Program ID",
  name: "Program Name",
  equipments: "Program Equipment",
  injuries: "Program Injuries",
  location: "Program Location",
  fitnessGoal: "Program Fitness Goal",
  workoutScoreLevel: "Program Workout Score Level",
  sundayWorkout: "Sunday Workout",
  mondayWorkout: "Monday Workout",
  tuesdayWorkout: "Tuesday Workout",
  wednesdayWorkout: "Wednesday Workout",
  thursdayWorkout: "Thursday Workout",
  fridayWorkout: "Friday Workout",
  saturdayWorkout: "Saturday Workout",
  easySubstitute: "Easy Program Substitute",
  hardSubstitute: "Hard Program Substitute",
  locationSubstitute: "Location Program Substitute",
};
