import React from "react";
import styled from "@emotion/styled";

import { Paper } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin: 32px 0;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function PrivacyPolicy() {
  return (
    <>
      <Brand />
      <Wrapper>
        <h1>
          Privacy Policy for Myotrim Enterprises, LLC's Mobile Corporate
          Wellness App
        </h1>
        <p>Last Updated: November 1, 2023</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to the Myotrim Mobile App. Myotrim Enterprises, LLC ("we,"
          "us," or "our") is committed to safeguarding the privacy and security
          of your personal information. This Privacy Policy explains how we
          collect, use, disclose, and protect your information when you use our
          Mobile Corporate Wellness App (the "App"). Please read this policy
          carefully to understand our views and practices regarding your
          personal data and how we will treat it.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We may collect the following types of personal and sensitive user
          data:
        </p>
        <ul>
          <li>
            Personal Information: Name, email address, age, gender, fitness
            level, health goals, and other contact details.
          </li>
          <li>
            Usage Data: Information about how you use and interact with our App,
            such as engagement metrics, clicks, and navigation through the App.
          </li>
          <li>
            Health Information: Self-reported data related to your health and
            wellness activities.
          </li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>We use the information we collect in the following ways:</p>
        <ul>
          <li>To provide, maintain, and improve the App's functionality.</li>
          <li>
            To personalize your experience by tailoring features,
            recommendations, and services to meet your needs.
          </li>
          <li>
            To develop new features and offerings based on user trends and
            preferences.
          </li>
          <li>
            To communicate with you for customer service, updates, and other
            informational purposes, including sending emails and push
            notifications.
          </li>
          <li>
            To analyze usage and engagement to better understand your
            preferences and improve our App.
          </li>
          <li>
            To prevent unauthorized access and maintain the security of your
            data.
          </li>
        </ul>

        <h2>4. How We Share Your Information</h2>
        <p>We may share your information in the following circumstances:</p>
        <ul>
          <li>
            Service Providers: We may share your information with third-party
            vendors who perform services on our behalf (e.g., cloud storage,
            analytics).
          </li>
          <li>
            With Your Employer: If you are using the App as part of a corporate
            wellness program, we may share aggregated data with your employer.
            However, no personal health information will be shared without your
            explicit consent.
          </li>
          <li>
            Legal Compliance: We may share your information to comply with
            applicable laws, regulations, or legal processes.
          </li>
          <li>
            To Protect Rights and Safety: We may share your information if we
            believe it is necessary to protect the rights, property, or safety
            of our users or others.
          </li>
        </ul>

        <h2>5. Data Security</h2>
        <p>
          We implement a variety of industry-standard security measures to
          protect your information. However, please note that no method of
          electronic transmission or storage is 100% secure. While we strive to
          use commercially acceptable means to protect your personal
          information, we cannot guarantee its absolute security.
        </p>

        <h2>6. Data Retention and Deletion</h2>
        <p>
          We retain your personal information for as long as necessary to
          fulfill the purposes for which we collected it, unless a longer
          retention period is required or permitted by law. You can request
          deletion of your data by contacting us directly, and we will take
          reasonable steps to delete your personal information from our active
          databases.
        </p>

        <h2>7. Your Privacy Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Request access to the personal information we hold about you.</li>
          <li>Request correction of any inaccurate information.</li>
          <li>Request deletion of your personal information.</li>
        </ul>
        <p>
          If you wish to exercise these rights, please contact us at
          info@myotrim.com.
        </p>

        <h2>8. Children’s Privacy</h2>
        <p>
          Our App is not intended for individuals under the age of 18. We do not
          knowingly collect personal information from children under 18. If we
          become aware that we have collected such information, we will take
          immediate steps to delete it.
        </p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated "Last Updated" date. We
          encourage you to review this Privacy Policy periodically for any
          changes. Your continued use of the App after any modifications
          indicates your acceptance of the updated policy.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions, concerns, or suggestions regarding this
          Privacy Policy, please contact us at:
        </p>
        <ul>
          <li>Email: info@myotrim.com</li>
          <li>
            Mailing Address: Myotrim Enterprises, LLC, PO Box 158, Richboro, PA
            18954
          </li>
        </ul>
      </Wrapper>
    </>
  );
}

export default PrivacyPolicy;
