import React, { useLayoutEffect, useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { Equipment } from "../../types/equipment";
import { useNavigate } from "react-router-dom";
import { Program } from "../../types/program";
import { Injury } from "../../types/injury";
import Table from "../../components/Table";
import { WorkoutScoreLevel } from "../../enums/WorkoutScoreLevel";
import { FitnessGoalType } from "../../enums/program";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { Workout } from "../../types/workout";
import {
  generateOptionsFromEnum,
  OptionsFromEnum,
} from "../../utils/generateOptionsFromEnum";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import EquipmentsService from "../../services/equipmentsService";
import InjuriesService from "../../services/injuriesService";
import ExerciseLocationsService from "../../services/exerciseLocationsService";
import useWindowWidth from "../../hooks/useWindowSize";

type ProgramsTableProps = {
  programs: Program[];
  count: number;
  loading: boolean;
  getPrograms: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateProgram: (program: Program) => void;
  removeProgram: (exercise: Program) => void;
};

const ProgramsTable: React.FC<ProgramsTableProps> = ({
  programs,
  count,
  loading,
  getPrograms,
  setPageNumber,
  setElementsPerPage,
  updateProgram,
  removeProgram,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const [equipmentOptions, setEquipmentOptions] = useState<OptionsFromEnum[]>(
    []
  );
  const [locationOptions, setLocationOptions] = useState<OptionsFromEnum[]>([]);
  const [injuriesOptions, setInjuriesOptions] = useState<OptionsFromEnum[]>([]);
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowWidth();
  const SCREEN_WIDTH = 2200;

  useLayoutEffect(() => {
    fetchDataForSelects();
  }, []);

  async function fetchDataForSelects() {
    const page: number = 0;
    const size: number = 100;
    const filter: string = "";
    const sortOrder: string = "";
    const sortField: string = "";

    const [equipmentsData, injuriesData, locationsData] = await Promise.all([
      EquipmentsService.getEquipments(page, size, filter, sortOrder, sortField),
      InjuriesService.getInjuries(page, size, filter, sortOrder, sortField),
      ExerciseLocationsService.getLocations(
        page,
        size,
        filter,
        sortOrder,
        sortField
      ),
    ]);
    setEquipmentOptions(
      equipmentsData.content.map((i) => ({ value: i.name, label: i.name }))
    );
    setInjuriesOptions(
      injuriesData.content
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map((i) => ({ value: i.name, label: i.name }))
    );
    setLocationOptions(
      locationsData.content.map((i) => ({ value: i.name, label: i.name }))
    );
  }

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    program: Program
  ) => {
    event.stopPropagation();
    handleMenuClose(program.id!);
    updateProgram(program);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    program: Program
  ) => {
    event.stopPropagation();
    handleMenuClose(program.id!);
    removeProgram(program);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      return navigate(`${params.row.id}/details`);
    }
  };

  const workoutScoreLevelOptions = generateOptionsFromEnum(WorkoutScoreLevel);
  const fitnessGoalTypeOptions = generateOptionsFromEnum(FitnessGoalType);
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Program ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Program Name",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "location",
      headerName: "Program Location",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(locationOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "equipments",
      headerName: "Program Equipment",
      width: windowWidth < SCREEN_WIDTH ? 200 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(equipmentOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.map((i: Equipment) => i.name).join(", ");
      },
    },
    {
      field: "injuries",
      headerName: "Program Injuries",
      width: windowWidth < SCREEN_WIDTH ? 200 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(injuriesOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.map((i: Injury) => i.name).join(", ");
      },
    },
    {
      field: "fitnessGoal",
      headerName: "Program Fitness Goal",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(fitnessGoalTypeOptions)],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "workoutScoreLevel",
      headerName: "Program Workout Score Level",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(workoutScoreLevelOptions)],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "sundayWorkout",
      headerName: "Sunday Workout",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Workout)?.name;
      },
    },
    {
      field: "mondayWorkout",
      headerName: "Monday Workout",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Workout)?.name;
      },
    },
    {
      field: "tuesdayWorkout",
      headerName: "Tuesday Workout",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Workout)?.name;
      },
    },
    {
      field: "wednesdayWorkout",
      headerName: "Wednesday Workout",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Workout)?.name;
      },
    },
    {
      field: "thursdayWorkout",
      headerName: "Thursday Workout",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Workout)?.name;
      },
    },
    {
      field: "fridayWorkout",
      headerName: "Friday Workout",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Workout)?.name;
      },
    },
    {
      field: "saturdayWorkout",
      headerName: "Saturday Workout",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Workout)?.name;
      },
    },
    {
      field: "easySubstitute",
      headerName: "Easy Program Substitute",
      width: windowWidth < SCREEN_WIDTH ? 200 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Program)?.name;
      },
    },
    {
      field: "hardSubstitute",
      headerName: "Hard Program Substitute",
      width: windowWidth < SCREEN_WIDTH ? 200 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Program)?.name;
      },
    },
    {
      field: "locationSubstitute",
      headerName: "Location Program Substitute",
      width: windowWidth < SCREEN_WIDTH ? 200 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Program)?.name;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const program = params.row as Program;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[program.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, program.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[program.id!]}
              open={Boolean(anchorEl[program.id!])}
              onClose={() => handleMenuClose(program.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, program)}>
                Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, program)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="programs"
      rows={programs}
      columns={columns}
      count={count}
      loading={loading}
      getData={getPrograms}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
      initialState={{ pinnedColumns: { right: ["actions"] } }}
    />
  );
};

export default ProgramsTable;
