import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import * as Yup from "yup";
import UserAutocomplete from "../../components/autocomplete/UserAutocomplete";
import { UserDTO } from "../../types/user";
import useToaster from "../../hooks/useToaster";
import WorkoutCheckinsService from "../../services/workoutCheckinsService";
import { WorkoutCheckin } from "../../types/workoutCheckin";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  checkinDate: Yup.date().required("Required"),
  checkinDayOfWeek: Yup.string().required("Required"),
  user: Yup.object().test(
    "has-properties",
    "User is required",
    (value) => value && Object.keys(value).length > 0
  ),
});

type CreateWorkoutCheckinFormProps = {
  mode: "create" | "update";
  workoutCheckin?: WorkoutCheckin;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export type WorkoutCheckinForm = {
  checkinDate: Date | null;
  checkinDayOfWeek?: string;
  user?: UserDTO;
  company: string;
};

function CreateEditWorkoutCheckinForm({
  mode,
  workoutCheckin,
  onSubmit,
  onCancel,
}: CreateWorkoutCheckinFormProps): JSX.Element {
  const { showToast } = useToaster();

  const initialValues: WorkoutCheckinForm = {
    checkinDate: workoutCheckin ? new Date(workoutCheckin.checkinDate) : null,
    checkinDayOfWeek: workoutCheckin?.checkinDayOfWeek || undefined,
    user: workoutCheckin?.user || undefined,
    company: workoutCheckin?.user?.company?.name || "",
  };

  async function handleSubmit(values: WorkoutCheckinForm) {
    const preparedData: WorkoutCheckin = {
      checkinDate: values?.checkinDate || new Date(),
      checkinDayOfWeek: values.checkinDayOfWeek!,
      user: values.user!,
    };

    await (mode === "create"
      ? createWorkoutCheckin(preparedData)
      : updateWWorkoutCheckin(preparedData, workoutCheckin?.id!)
    ).then(onSubmit);
  }

  async function createWorkoutCheckin(workoutCheckin: WorkoutCheckin) {
    await WorkoutCheckinsService.create(workoutCheckin)
      .then(() => showToast("Workout Checkin successfully created!", "success"))
      .catch((err) => showToast(err.message));
  }

  async function updateWWorkoutCheckin(
    newWorkoutCheckin: WorkoutCheckin,
    id: number
  ) {
    await WorkoutCheckinsService.update({
      ...newWorkoutCheckin,
      id,
    })
      .then(() => showToast("Workout Checkin successfully updated!", "success"))
      .catch((err) => showToast(err.message));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent style={{ width: "430px" }}>
          <form onSubmit={handleSubmit}>
            <DemoContainer components={["DatePicker"]}>
              <Box
                component="div"
                my={2}
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <DatePicker
                  label="Checkin Date"
                  value={values.checkinDate}
                  format="MM-dd-yyyy"
                  onChange={(date) => setFieldValue("checkinDate", date)}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                      error: Boolean(touched.checkinDate && errors.checkinDate),
                      helperText: touched.checkinDate && errors.checkinDate,
                    },
                  }}
                />
              </Box>
            </DemoContainer>

            <UserAutocomplete
              id="user-select"
              label="User"
              selectedOption={values.user}
              onOptionSelect={(value) => {
                setFieldValue("user", value);
                setFieldValue("company", value?.company?.name);
              }}
              searchBy="name"
              isTouched={!!touched.user}
              error={errors.user || ""}
            />

            <TextField
              name="company"
              label="Company"
              value={values.company}
              disabled={true}
              error={Boolean(touched.company && errors.company)}
              fullWidth
              helperText={touched.company && errors.company}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="checkinDayOfWeek"
              label="Checkin Day Of Week"
              value={values.checkinDayOfWeek}
              type="number"
              error={Boolean(
                touched.checkinDayOfWeek && errors.checkinDayOfWeek
              )}
              fullWidth
              helperText={touched.checkinDayOfWeek && errors.checkinDayOfWeek}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                component="div"
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditWorkoutCheckinForm;
