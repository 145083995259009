import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Backdrop,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import MeasurementsService from "../../services/MeasurementsService";
import { Measurement } from "../../types/measurement";
import CreateMeasurementForm from "./CreateMeasurementForm";
import useToaster from "../../hooks/useToaster";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const StyledBackdrop = styled(Backdrop)`
  color: #376fd0;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

function CreateMeasurementPage() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const [isLoading, setIsLoading] = useState(false);

  function createMeasurementHandler(measurement: Measurement) {
    setIsLoading(true);
    MeasurementsService.createMeasurement(measurement)
      .then(() => showToast("Measurement successfully created!", "success"))
      .then(() => navigate("/private/measurements"))
      .catch((err) => {
        let message = err.message;
        if (err.response && err.response.data && err.response.data.details) {
          const errorDetails = err.response.data.details;
          message = Object.entries(errorDetails)
            .map(([key, value]) => `${key}: ${value};`)
            .join("\n");
        }
        showToast(message);
      })
      .finally(() => setIsLoading(false));
  }

  function cancel() {
    navigate("/private/measurements");
  }

  return (
    <React.Fragment>
      <Helmet title="Create Measurement" />
      <Typography variant="h3" gutterBottom display="inline">
        Create Measurement
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/measurements">
          Measurement
        </Link>
        <Typography>Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StyledBackdrop open={isLoading}>
                <CircularProgress color="inherit" />
              </StyledBackdrop>
              <CreateMeasurementForm
                onSubmit={(measurement) =>
                  createMeasurementHandler(measurement)
                }
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateMeasurementPage;
