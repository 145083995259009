import React, { useLayoutEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Backdrop,
  Box,
  Button as MuiButton,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl as MuiFormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import {
  AlcoholLevelEnum,
  BooleanEnum,
  CardioFitnessLevelEnum,
  ConfidenceLevelEnum,
  DifficultTimeReasonEnum,
  ExerciseFrequencyEnum,
  FinancialLevelEnum,
  GoalEnum,
  HealthQuestionnaire,
  MealFrequencyLevelEnum,
  MealPreferenceLevelEnum,
  NutritionLevelEnum,
  PantsSizeEnum,
  RelationshipLevelEnum,
  SleepLevelEnum,
  SocialSupportLevelEnum,
  StressLevelEnum,
  WaistSizeEnum,
  WeightTrainingLevelEnum,
  WorkoutDifficultyLevelEnum,
  WorkoutIntensityLevelEnum,
} from "../../types/health-questionnaire";
import { Location, LocationEnum } from "../../types/location";
import { Injury, InjuryEnum } from "../../types/injury";
import { Equipment, EquipmentEnum } from "../../types/equipment";
import ExerciseLocationsService from "../../services/exerciseLocationsService";
import InjuriesService from "../../services/injuriesService";
import EquipmentsService from "../../services/equipmentsService";
import UsersService from "../../services/usersService";
import { GenderEnum, UserDTO } from "../../types/user";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { HealthQuestionnaireQuestions } from "./healthQuestionnaireQuestions";
import HomeEquipments from "./HomeEquipments";
import InputMask from "react-input-mask";
import UserAutocomplete from "../../components/autocomplete/UserAutocomplete";
import HealthQuestionnairesService from "../../services/HealthQuestionnairesService";
import useToaster from "../../hooks/useToaster";
import {
  PhysicalGoalType,
  SelfImprovementGoalType,
} from "../../types/goalProgram";
import convertBooleanToString from "../../utils/convertBooleanToString";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const StyledBackdrop = styled(Backdrop)`
  color: #376fd0;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
type HealthQuestionnaireForm = {
  date: Date;
  currentWeight: string;
  imageMeasurement: string;
  pantsSize: string | number;
  waistSize: string | number;
  highBloodPressure: string;
  highCholesterol: string;
  diabetes: string;
  acidReflux: string;
  heartDisease: string;
  asthma: string;
  medication: string;
  injuryIds: number[];
  exerciseLocationId?: string | number;
  equipmentIds: number[];
  fitnessGoal: string;
  weightTrainingLevel: string;
  cardioFitnessLevel: string;
  exerciseFrequency: string;
  exerciseIntensityLevel: string;
  workoutDifficulty: string;
  physicalGoal: string;
  selfImprovementGoal: string;
  currentDiet: string;
  mealFrequency: string;
  mealPreference: string;
  socialSupportLevel: string;
  financialSituationLevel: string;
  dailyStressLevel: string;
  sleepLevel: string;
  confidenceLevel: string;
  relationshipLevel: string;
  alcohol: string;
  smokeCigarettes: string;
  difficultTime: string;
  user?: UserDTO;
};

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  date: Yup.string().required("Required"),
  currentWeight: Yup.string(),
  imageMeasurement: Yup.string(),
  pantsSize: Yup.string(),
  waistSize: Yup.string(),
  highBloodPressure: Yup.string().required("Required"),
  highCholesterol: Yup.string().required("Required"),
  diabetes: Yup.string().required("Required"),
  acidReflux: Yup.string().required("Required"),
  heartDisease: Yup.string().required("Required"),
  asthma: Yup.string().required("Required"),
  medication: Yup.string().required("Required"),
  injuryIds: Yup.array()
    .min(1, "Please select at least one injury")
    .required("Please select at least one injury"),
  exerciseLocationId: Yup.string().required("Required"),
  equipmentIds: Yup.array(),
  fitnessGoal: Yup.string().required("Required"),
  weightTrainingLevel: Yup.string().required("Required"),
  cardioFitnessLevel: Yup.string().required("Required"),
  exerciseFrequency: Yup.string().required("Required"),
  exerciseIntensityLevel: Yup.string().required("Required"),
  workoutDifficulty: Yup.string().required("Required"),
  physicalGoal: Yup.string().required("Required"),
  selfImprovementGoal: Yup.string().required("Required"),
  currentDiet: Yup.string().required("Required"),
  mealFrequency: Yup.string().required("Required"),
  mealPreference: Yup.string().required("Required"),
  socialSupportLevel: Yup.string().required("Required"),
  financialSituationLevel: Yup.string().required("Required"),
  dailyStressLevel: Yup.string().required("Required"),
  sleepLevel: Yup.string().required("Required"),
  confidenceLevel: Yup.string().required("Required"),
  relationshipLevel: Yup.string().required("Required"),
  alcohol: Yup.string().required("Required"),
  smokeCigarettes: Yup.string().required("Required"),
  difficultTime: Yup.mixed().nullable().required("Required"),
  user: Yup.object().test(
    "has-properties",
    "User is required",
    (value) => value && Object.keys(value).length > 0
  ),
});

type CreateHealthQuestionnaireFormProps = {
  mode: "create" | "update";
  questionnaireId?: number;
  onSubmit: (healthQuestionnaire: HealthQuestionnaire) => void;
  onCancel: () => void;
};

function CreateEditHealthQuestionnaireForm({
  mode,
  questionnaireId,
  onSubmit,
  onCancel,
}: CreateHealthQuestionnaireFormProps): JSX.Element {
  const [locations, setLocations] = useState<Location[]>([]);
  const [injuries, setInjuries] = useState<Injury[]>([]);
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [questionnaire, setQuestionnaires] = useState<HealthQuestionnaire>();
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToaster();

  const initialValues: HealthQuestionnaireForm = {
    date: (questionnaire && new Date(questionnaire?.date)) || new Date(),
    highBloodPressure:
      convertBooleanToString(questionnaire?.highBloodPressure) || "",
    highCholesterol:
      convertBooleanToString(questionnaire?.highCholesterol) || "",
    diabetes: convertBooleanToString(questionnaire?.diabetes) || "",
    acidReflux: convertBooleanToString(questionnaire?.acidReflux) || "",
    heartDisease: convertBooleanToString(questionnaire?.heartDisease) || "",
    asthma: convertBooleanToString(questionnaire?.asthma) || "",
    medication: convertBooleanToString(questionnaire?.medication) || "",
    injuryIds: questionnaire?.injuries.map((i) => i.id!) || [],
    exerciseLocationId: questionnaire?.exerciseLocation.id || "",
    equipmentIds: questionnaire?.equipments.map((e) => e.id!) || [],
    fitnessGoal: questionnaire?.fitnessGoal || "",
    weightTrainingLevel: questionnaire?.weightTrainingLevel || "",
    cardioFitnessLevel: questionnaire?.cardioFitnessLevel || "",
    exerciseFrequency: questionnaire?.exerciseFrequency || "",
    exerciseIntensityLevel: questionnaire?.exerciseIntensityLevel || "",
    workoutDifficulty: questionnaire?.workoutDifficulty || "",
    physicalGoal: questionnaire?.physicalGoal || "",
    selfImprovementGoal: questionnaire?.selfImprovementGoal || "",
    currentDiet: questionnaire?.currentDiet || "",
    mealFrequency: questionnaire?.mealFrequency || "",
    mealPreference: questionnaire?.mealPreference || "",
    socialSupportLevel: questionnaire?.socialSupportLevel || "",
    financialSituationLevel: questionnaire?.financialSituationLevel || "",
    dailyStressLevel: questionnaire?.dailyStressLevel || "",
    sleepLevel: questionnaire?.sleepLevel || "",
    confidenceLevel: questionnaire?.confidenceLevel || "",
    relationshipLevel: questionnaire?.relationshipLevel || "",
    alcohol: questionnaire?.alcohol || "",
    smokeCigarettes:
      convertBooleanToString(questionnaire?.smokeCigarettes) || "",
    difficultTime: questionnaire?.difficultTime || "",
    currentWeight: questionnaire
      ? questionnaire?.currentWeight?.toString()
      : "",
    pantsSize: questionnaire?.pantsSize || "",
    waistSize: questionnaire?.waistSize || "",
    imageMeasurement:
      convertBooleanToString(questionnaire?.imageMeasurement) || "",
    user: questionnaire?.user || undefined,
  };
  const formLabels = HealthQuestionnaireQuestions;
  useLayoutEffect(() => {
    fetchDataForSelects();
  }, [questionnaireId]);

  async function fetchDataForSelects() {
    setIsLoading(true);
    const page: number = 0;
    const size: number = 100;
    const filter: string = "";
    const search: string = "";
    const sortOrder: string = "";
    const sortField: string = "";
    let questionnaireData;

    if (questionnaireId) {
      try {
        questionnaireData =
          await HealthQuestionnairesService.getQuestionnaireBy(questionnaireId);
      } catch (error) {
        if (error instanceof Error) {
          showToast(error.message);
        } else {
          showToast("An unexpected error occurred with loading questionnaire.");
        }
      }
    }

    const [locationsData, injuriesData, equipmentsData] = await Promise.all([
      ExerciseLocationsService.getLocations(
        page,
        size,
        filter,
        sortOrder,
        sortField
      ),
      InjuriesService.getInjuries(page, size, filter, sortOrder, sortField),
      EquipmentsService.getEquipments(page, size, filter, sortOrder, sortField),
      UsersService.getUsers(page, size, filter, search, sortOrder, sortField),
    ]);

    setLocations(locationsData.content);
    setInjuries(injuriesData.content);
    setEquipments(
      equipmentsData.content.filter((eq) => HomeEquipments.includes(eq.name))
    );
    if (questionnaireData) setQuestionnaires(questionnaireData);

    setIsLoading(false);
  }
  function handleSubmit(values: HealthQuestionnaireForm) {
    const preparedData: HealthQuestionnaire = {
      date: values?.date || new Date(),
      injuries: injuries.filter((i) => values.injuryIds?.includes(i.id!)),
      equipments: equipments.filter((e) =>
        values.equipmentIds?.includes(e.id!)
      ),
      user: values.user,
      exerciseLocation: locations.find(
        (l) => l.id === values.exerciseLocationId
      )!,
      highBloodPressure: values.highBloodPressure === "YES",
      highCholesterol: values.highCholesterol === "YES",
      diabetes: values.diabetes === "YES",
      acidReflux: values.acidReflux === "YES",
      heartDisease: values.heartDisease === "YES",
      asthma: values.asthma === "YES",
      medication: values.medication === "YES",
      fitnessGoal: values.fitnessGoal,
      weightTrainingLevel: values.weightTrainingLevel,
      cardioFitnessLevel: values.cardioFitnessLevel,
      exerciseFrequency: values.exerciseFrequency,
      exerciseIntensityLevel: values.exerciseIntensityLevel,
      workoutDifficulty: values.workoutDifficulty,
      physicalGoal: values.physicalGoal,
      selfImprovementGoal: values.selfImprovementGoal,
      currentDiet: values.currentDiet,
      mealFrequency: values.mealFrequency,
      mealPreference: values.mealPreference,
      socialSupportLevel: values.socialSupportLevel,
      financialSituationLevel: values.financialSituationLevel,
      dailyStressLevel: values.dailyStressLevel,
      sleepLevel: values.sleepLevel,
      confidenceLevel: values.confidenceLevel,
      relationshipLevel: values.relationshipLevel,
      alcohol: values.alcohol,
      smokeCigarettes: values.smokeCigarettes === "YES",
      difficultTime: values.difficultTime,
      imageMeasurement: values.imageMeasurement === "YES",
      pantsSize:
        values.user?.gender === GenderEnum.Female ? values.pantsSize : null,
      waistSize:
        values.user?.gender === GenderEnum.Male ? values.waistSize : null,
      currentWeight: Number(values.currentWeight),
    } as unknown as HealthQuestionnaire;
    mode === "create"
      ? onSubmit(preparedData)
      : onSubmit({ ...questionnaire, ...(preparedData as any) });
  }

  function locationFieldChangeHandler(
    e: SelectChangeEvent<unknown>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    setFieldValue("exerciseLocationId", parseInt(e.target.value as string));
    if (
      locations.find((l) => l.id === parseInt(e.target.value as string))!
        .name !== LocationEnum.Home
    ) {
      setFieldValue("equipmentIds", []);
    }
  }

  function getNoEquipmentID(): number {
    return (
      equipments.find((eq) => eq.name === EquipmentEnum.NoEquipment)!.id || 1
    );
  }

  function getNoneInjuresID(): number {
    return injuries.find((inj) => inj.name === InjuryEnum.None)!.id || 1;
  }

  return (
    <>
      {isLoading ? (
        <StyledBackdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <CardContent>
              {!isLoading && (
                <form onSubmit={handleSubmit}>
                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["date"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Date"
                          value={values.date}
                          format="MM-dd-yyyy"
                          onChange={(date) => setFieldValue("date", date)}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              fullWidth: true,
                              error: Boolean(touched.date && errors.date),
                            },
                          }}
                        />
                      </DemoContainer>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["user"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <UserAutocomplete
                        id="user-select"
                        label="User"
                        selectedOption={values.user}
                        onOptionSelect={(value) => setFieldValue("user", value)}
                        searchBy="name"
                        isTouched={!!touched.user}
                        error={errors.user || ""}
                      />
                    </Grid>
                  </Grid>

                  <Typography variant="h3" mt={8} align="center">
                    Health History
                  </Typography>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["currentWeight"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InputMask
                        mask="999"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.currentWeight}
                        maskPlaceholder={""}
                      >
                        <TextField
                          name="currentWeight"
                          label="Current Weight"
                          fullWidth
                          error={Boolean(
                            touched.currentWeight && errors.currentWeight
                          )}
                          helperText={
                            touched.currentWeight && errors.currentWeight
                          }
                          variant="outlined"
                          my={2}
                        />
                      </InputMask>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["highBloodPressure"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.highBloodPressure && errors.highBloodPressure
                        )}
                      >
                        <InputLabel id="high-blood-pressure-select-label">
                          High Blood Pressure
                        </InputLabel>
                        <Select
                          name="highBloodPressure"
                          labelId="high-blood-pressure-select-label"
                          id="high-blood-pressure-select"
                          value={values.highBloodPressure}
                          label="High Blood Pressure"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("highBloodPressure", e.target.value)
                          }
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem
                              key={`high-blood-pressure-${key}`}
                              value={key}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.highBloodPressure &&
                          errors.highBloodPressure && (
                            <FormHelperText error>
                              {errors.highBloodPressure}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["highCholesterol"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.highCholesterol && errors.highCholesterol
                        )}
                      >
                        <InputLabel id="high-cholesterol-select-label">
                          High Cholesterol
                        </InputLabel>
                        <Select
                          name="highCholesterol"
                          labelId="high-cholesterol-select-label"
                          id="high-cholesterol-select"
                          value={values.highCholesterol}
                          label="High Cholesterol"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("highCholesterol", e.target.value)
                          }
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem
                              key={`high-blood-pressure-${key}`}
                              value={key}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.highCholesterol && errors.highCholesterol && (
                          <FormHelperText error>
                            {errors.highCholesterol}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["diabetes"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(touched.diabetes && errors.diabetes)}
                      >
                        <InputLabel id="diabetes-select-label">
                          Diabetes
                        </InputLabel>
                        <Select
                          name="diabetes"
                          labelId="diabetes-select-label"
                          id="diabetes-select"
                          value={values.diabetes}
                          label="Diabetes"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("diabetes", e.target.value)
                          }
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem key={`diabetes-${key}`} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.diabetes && errors.diabetes && (
                          <FormHelperText error>
                            {errors.diabetes}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["acidReflux"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(touched.acidReflux && errors.acidReflux)}
                      >
                        <InputLabel id="acid-reflux-select-label">
                          Acid Reflux
                        </InputLabel>
                        <Select
                          name="acidReflux"
                          labelId="acidReflux-select-label"
                          id="acidReflux-select"
                          value={values.acidReflux}
                          label="Acid Reflux"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("acidReflux", e.target.value)
                          }
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem key={`acidReflux-${key}`} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.acidReflux && errors.acidReflux && (
                          <FormHelperText error>
                            {errors.acidReflux}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["heartDisease"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.heartDisease && errors.heartDisease
                        )}
                      >
                        <InputLabel id="heartDisease-select-label">
                          Heart Disease
                        </InputLabel>
                        <Select
                          name="heartDisease"
                          labelId="heartDisease-select-label"
                          id="heartDisease-select"
                          value={values.heartDisease}
                          label="Heart Disease"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("heartDisease", e.target.value)
                          }
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem key={`heartDisease-${key}`} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.heartDisease && errors.heartDisease && (
                          <FormHelperText error>
                            {errors.heartDisease}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["asthma"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(touched.asthma && errors.asthma)}
                      >
                        <InputLabel id="asthma-select-label">Asthma</InputLabel>
                        <Select
                          name="asthma"
                          labelId="asthma-select-label"
                          id="asthma-select"
                          value={values.asthma}
                          label="Asthma"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("asthma", e.target.value)
                          }
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem key={`asthma-${key}`} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.asthma && errors.asthma && (
                          <FormHelperText error>{errors.asthma}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["medication"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(touched.medication && errors.medication)}
                      >
                        <InputLabel id="medication-select-label">
                          Medication
                        </InputLabel>
                        <Select
                          name="medication"
                          labelId="medication-select-label"
                          id="medication-select"
                          value={values.medication}
                          label="Medication"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("medication", e.target.value)
                          }
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem key={`medication-${key}`} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.medication && errors.medication && (
                          <FormHelperText error>
                            {errors.medication}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["injuries"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(touched.injuryIds && errors.injuryIds)}
                      >
                        <InputLabel id="injury-select-label">
                          Workout Injuries
                        </InputLabel>
                        <Select
                          labelId="injury-select-label"
                          id="injury-select"
                          multiple
                          onBlur={handleBlur}
                          value={values.injuryIds}
                          onChange={(e) =>
                            (e.target.value as number[]).includes(
                              getNoneInjuresID()
                            )
                              ? setFieldValue("injuryIds", [getNoneInjuresID()])
                              : setFieldValue(
                                  "injuryIds",
                                  e.target.value as number[]
                                )
                          }
                          input={<OutlinedInput label="Workout Injuries" />}
                          renderValue={(selected) =>
                            injuries
                              .filter(
                                (injury) =>
                                  injury.id !== undefined &&
                                  (selected as number[]).includes(injury.id)
                              )
                              .map((injury) => injury.name)
                              .join(", ")
                          }
                        >
                          {injuries.length > 0 &&
                            injuries.map((injury) => (
                              <MenuItem
                                key={`injury-${injury.id}`}
                                value={injury.id}
                                disabled={
                                  Boolean(injury.id !== getNoneInjuresID()) &&
                                  Boolean(
                                    values.injuryIds.includes(
                                      getNoneInjuresID()
                                    )
                                  )
                                }
                              >
                                <Checkbox
                                  checked={
                                    injury.id !== undefined &&
                                    values.injuryIds.includes(injury.id)
                                  }
                                />
                                <ListItemText primary={injury.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.injuryIds && errors.injuryIds && (
                          <FormHelperText error>
                            {errors.injuryIds}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Typography variant="h3" mt={8} align="center">
                    Exercise & Goals
                  </Typography>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["exerciseLocation"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.exerciseLocationId &&
                            errors.exerciseLocationId
                        )}
                      >
                        <InputLabel id="location-select-label">
                          Workout Location
                        </InputLabel>
                        <Select
                          name="exerciseLocationId"
                          labelId="location-select-label"
                          id="location-select"
                          value={values.exerciseLocationId}
                          label="Workout Location"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            locationFieldChangeHandler(e, setFieldValue)
                          }
                        >
                          {locations.length > 0 &&
                            locations.map((location) => (
                              <MenuItem
                                key={`location-${location.id}`}
                                value={location.id}
                              >
                                {location.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.exerciseLocationId &&
                          errors.exerciseLocationId && (
                            <FormHelperText error>
                              {errors.exerciseLocationId}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["equipments"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.equipmentIds && errors.equipmentIds
                        )}
                      >
                        <InputLabel id="equipment-select-label">
                          Workout Equipment
                        </InputLabel>
                        <Select
                          labelId="equipment-select-label"
                          id="equipment-select"
                          multiple
                          value={values.equipmentIds}
                          sx={{
                            "&.Mui-disabled": {
                              backgroundColor: "rgba(0, 0, 0, 0.05)",
                            },
                          }}
                          disabled={
                            !Boolean(values.exerciseLocationId) ||
                            Boolean(
                              values.exerciseLocationId &&
                                (locations.find(
                                  (l) =>
                                    l.id ===
                                    parseInt(
                                      values.exerciseLocationId as string
                                    )
                                )?.name || "") === LocationEnum.Gym
                            )
                          }
                          onBlur={handleBlur}
                          onChange={(e) =>
                            (e.target.value as number[]).includes(
                              getNoEquipmentID()
                            )
                              ? setFieldValue("equipmentIds", [
                                  getNoEquipmentID(),
                                ])
                              : setFieldValue(
                                  "equipmentIds",
                                  e.target.value as number[]
                                )
                          }
                          input={<OutlinedInput label="Workout Equipment" />}
                          renderValue={(selected) =>
                            equipments
                              .filter(
                                (equipment) =>
                                  equipment.id !== undefined &&
                                  (selected as number[]).includes(equipment.id)
                              )
                              .map((equipment) => equipment.name)
                              .join(", ")
                          }
                        >
                          {equipments.length > 0 &&
                            equipments.map((equipment) => (
                              <MenuItem
                                key={`equipment-${equipment.id}`}
                                value={equipment.id}
                                disabled={
                                  Boolean(
                                    equipment.id !== getNoEquipmentID()
                                  ) &&
                                  Boolean(
                                    values.equipmentIds.includes(
                                      getNoEquipmentID()
                                    )
                                  )
                                }
                              >
                                <Checkbox
                                  checked={
                                    equipment.id !== undefined &&
                                    values.equipmentIds.includes(equipment.id)
                                  }
                                />
                                <ListItemText primary={equipment.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.equipmentIds && errors.equipmentIds && (
                          <FormHelperText error>
                            {errors.equipmentIds}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["fitnessGoal"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.fitnessGoal && errors.fitnessGoal
                        )}
                      >
                        <InputLabel id="fitnessGoal-select-label">
                          Fitness Goal
                        </InputLabel>
                        <Select
                          name="fitnessGoal"
                          labelId="fitnessGoal-select-label"
                          id="fitnessGoal-select"
                          value={values.fitnessGoal}
                          label="Fitness Goal"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("fitnessGoal", e.target.value)
                          }
                        >
                          {Object.entries(GoalEnum).map(([key, value]) => (
                            <MenuItem key={`fitnessGoal-${key}`} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.fitnessGoal && errors.fitnessGoal && (
                          <FormHelperText error>
                            {errors.fitnessGoal}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["weightTrainingLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.weightTrainingLevel &&
                            errors.weightTrainingLevel
                        )}
                      >
                        <InputLabel id="weightTrainingLevel-select-label">
                          Weight Training Level
                        </InputLabel>
                        <Select
                          name="weightTrainingLevel"
                          labelId="weightTrainingLevel-select-label"
                          id="weightTrainingLevel-select"
                          value={values.weightTrainingLevel}
                          label="Weight Training Level"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("weightTrainingLevel", e.target.value)
                          }
                        >
                          {Object.entries(WeightTrainingLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`weightTrainingLevel-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.weightTrainingLevel &&
                          errors.weightTrainingLevel && (
                            <FormHelperText error>
                              {errors.weightTrainingLevel}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["cardioFitnessLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.cardioFitnessLevel &&
                            errors.cardioFitnessLevel
                        )}
                      >
                        <InputLabel id="cardioFitnessLevel-select-label">
                          Cardio Fitness Level
                        </InputLabel>
                        <Select
                          name="cardioFitnessLevel"
                          labelId="cardioFitnessLevel-select-label"
                          id="cardioFitnessLevel-select"
                          value={values.cardioFitnessLevel}
                          label="cardioFitnessLevel"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("cardioFitnessLevel", e.target.value)
                          }
                        >
                          {Object.entries(CardioFitnessLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`cardioFitnessLevel-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.cardioFitnessLevel &&
                          errors.cardioFitnessLevel && (
                            <FormHelperText error>
                              {errors.cardioFitnessLevel}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["exerciseFrequency"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.exerciseFrequency && errors.exerciseFrequency
                        )}
                      >
                        <InputLabel id="exerciseFrequency-select-label">
                          Exercise Frequency
                        </InputLabel>
                        <Select
                          name="exerciseFrequency"
                          labelId="exerciseFrequency-select-label"
                          id="exerciseFrequency-select"
                          value={values.exerciseFrequency}
                          label="Exercise Frequency"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("exerciseFrequency", e.target.value)
                          }
                        >
                          {Object.entries(ExerciseFrequencyEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`exerciseFrequency-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.exerciseFrequency &&
                          errors.exerciseFrequency && (
                            <FormHelperText error>
                              {errors.exerciseFrequency}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["exerciseIntensityLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.exerciseIntensityLevel &&
                            errors.exerciseIntensityLevel
                        )}
                      >
                        <InputLabel id="exerciseIntensityLevel-select-label">
                          Exercise Intensity Level
                        </InputLabel>
                        <Select
                          name="exerciseIntensityLevel"
                          labelId="exerciseIntensityLevel-select-label"
                          id="exerciseIntensityLevel-select"
                          value={values.exerciseIntensityLevel}
                          label="Exercise Intensity Level"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue(
                              "exerciseIntensityLevel",
                              e.target.value
                            )
                          }
                        >
                          {Object.entries(WorkoutIntensityLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`exerciseIntensityLevel-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.exerciseIntensityLevel &&
                          errors.exerciseIntensityLevel && (
                            <FormHelperText error>
                              {errors.exerciseIntensityLevel}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["workoutDifficulty"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.workoutDifficulty && errors.workoutDifficulty
                        )}
                      >
                        <InputLabel id="workoutDifficulty-select-label">
                          Workout Difficulty
                        </InputLabel>
                        <Select
                          name="workoutDifficulty"
                          labelId="workoutDifficulty-select-label"
                          id="workoutDifficulty-select"
                          value={values.workoutDifficulty}
                          label="Workout Difficulty"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("workoutDifficulty", e.target.value)
                          }
                        >
                          {Object.entries(WorkoutDifficultyLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`workoutDifficulty-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.workoutDifficulty &&
                          errors.workoutDifficulty && (
                            <FormHelperText error>
                              {errors.workoutDifficulty}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["physicalGoal"]}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.physicalGoal && errors.physicalGoal
                        )}
                      >
                        <InputLabel id="physicalGoal-select-label">
                          Physical Goal
                        </InputLabel>
                        <Select
                          name="physicalGoal"
                          labelId="physicalGoal-select-label"
                          id="physicalGoal-select"
                          value={values.physicalGoal}
                          label="Physical Goal"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("physicalGoal", e.target.value)
                          }
                        >
                          {Object.entries(PhysicalGoalType).map(
                            ([key, value]) => (
                              <MenuItem key={`physicalGoal-${key}`} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.physicalGoal && errors.physicalGoal && (
                          <FormHelperText error>
                            {errors.physicalGoal}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["selfImprovementGoal"]}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.selfImprovementGoal &&
                            errors.selfImprovementGoal
                        )}
                      >
                        <InputLabel id="selfImprovementGoal-select-label">
                          Self Improvement Goal
                        </InputLabel>
                        <Select
                          name="selfImprovementGoal"
                          labelId="selfImprovementGoal-select-label"
                          id="selfImprovementGoal-select"
                          value={values.selfImprovementGoal}
                          label="Self Improvement Goal"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("selfImprovementGoal", e.target.value)
                          }
                        >
                          {Object.entries(SelfImprovementGoalType).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`selfImprovementGoal-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.selfImprovementGoal &&
                          errors.selfImprovementGoal && (
                            <FormHelperText error>
                              {errors.selfImprovementGoal}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Typography variant="h3" mt={8} align="center">
                    Nutrition
                  </Typography>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["currentDiet"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.currentDiet && errors.currentDiet
                        )}
                      >
                        <InputLabel id="currentDiet-select-label">
                          Current Diet
                        </InputLabel>
                        <Select
                          name="currentDiet"
                          labelId="currentDiet-select-label"
                          id="currentDiet-select"
                          value={values.currentDiet}
                          label="Current Diet"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("currentDiet", e.target.value)
                          }
                        >
                          {Object.entries(NutritionLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem key={`currentDiet-${key}`} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.currentDiet && errors.currentDiet && (
                          <FormHelperText error>
                            {errors.currentDiet}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["mealFrequency"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.mealFrequency && errors.mealFrequency
                        )}
                      >
                        <InputLabel id="mealFrequency-select-label">
                          Meal Frequency
                        </InputLabel>
                        <Select
                          name="mealFrequency"
                          labelId="mealFrequency-select-label"
                          id="mealFrequency-select"
                          value={values.mealFrequency}
                          label="Meal Frequency"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("mealFrequency", e.target.value)
                          }
                        >
                          {Object.entries(MealFrequencyLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`mealFrequency-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.mealFrequency && errors.mealFrequency && (
                          <FormHelperText error>
                            {errors.mealFrequency}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["mealPreference"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.mealPreference && errors.mealPreference
                        )}
                      >
                        <InputLabel id="mealPreference-select-label">
                          Meal Preference
                        </InputLabel>
                        <Select
                          name="mealPreference"
                          labelId="mealPreference-select-label"
                          id="mealPreference-select"
                          value={values.mealPreference}
                          label="Meal Preference"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("mealPreference", e.target.value)
                          }
                        >
                          {Object.entries(MealPreferenceLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`mealPreference-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.mealPreference && errors.mealPreference && (
                          <FormHelperText error>
                            {errors.mealPreference}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Typography variant="h3" mt={8} align="center">
                    Lifestyle
                  </Typography>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["socialSupportLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.socialSupportLevel &&
                            errors.socialSupportLevel
                        )}
                      >
                        <InputLabel id="socialSupportLevel-select-label">
                          Social Support Level
                        </InputLabel>
                        <Select
                          name="socialSupportLevel"
                          labelId="socialSupportLevel-select-label"
                          id="socialSupportLevel-select"
                          value={values.socialSupportLevel}
                          label="Social Support Level"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("socialSupportLevel", e.target.value)
                          }
                        >
                          {Object.entries(SocialSupportLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`socialSupportLevel-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.socialSupportLevel &&
                          errors.socialSupportLevel && (
                            <FormHelperText error>
                              {errors.socialSupportLevel}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["financialSituationLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.financialSituationLevel &&
                            errors.financialSituationLevel
                        )}
                      >
                        <InputLabel id="financialSituationLevel-select-label">
                          Financial Situation Level
                        </InputLabel>
                        <Select
                          name="financialSituationLevel"
                          labelId="financialSituationLevel-select-label"
                          id="financialSituationLevel-select"
                          value={values.financialSituationLevel}
                          label="Financial Situation Level"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue(
                              "financialSituationLevel",
                              e.target.value
                            )
                          }
                        >
                          {Object.entries(FinancialLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`financialSituationLevel-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.financialSituationLevel &&
                          errors.financialSituationLevel && (
                            <FormHelperText error>
                              {errors.financialSituationLevel}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["dailyStressLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.dailyStressLevel && errors.dailyStressLevel
                        )}
                      >
                        <InputLabel id="dailyStressLevel-select-label">
                          Daily Stress Level
                        </InputLabel>
                        <Select
                          name="dailyStressLevel"
                          labelId="dailyStressLevel-select-label"
                          id="dailyStressLevel-select"
                          value={values.dailyStressLevel}
                          label="Daily Stress Level"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("dailyStressLevel", e.target.value)
                          }
                        >
                          {Object.entries(StressLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`dailyStressLevel-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.dailyStressLevel &&
                          errors.dailyStressLevel && (
                            <FormHelperText error>
                              {errors.dailyStressLevel}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["sleepLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(touched.sleepLevel && errors.sleepLevel)}
                      >
                        <InputLabel id="sleepLevel-select-label">
                          Sleep Level
                        </InputLabel>
                        <Select
                          name="sleepLevel"
                          labelId="sleepLevel-select-label"
                          id="sleepLevel-select"
                          value={values.sleepLevel}
                          label="Sleep Level"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("sleepLevel", e.target.value)
                          }
                        >
                          {Object.entries(SleepLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem key={`sleepLevel-${key}`} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.sleepLevel && errors.sleepLevel && (
                          <FormHelperText error>
                            {errors.sleepLevel}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["confidenceLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.confidenceLevel && errors.confidenceLevel
                        )}
                      >
                        <InputLabel id="confidenceLevel-select-label">
                          Confidence Level
                        </InputLabel>
                        <Select
                          name="confidenceLevel"
                          labelId="confidenceLevel-select-label"
                          id="confidenceLevel-select"
                          value={values.confidenceLevel}
                          label="Confidence Level"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("confidenceLevel", e.target.value)
                          }
                        >
                          {Object.entries(ConfidenceLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`confidenceLevel-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.confidenceLevel && errors.confidenceLevel && (
                          <FormHelperText error>
                            {errors.confidenceLevel}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["relationshipLevel"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.relationshipLevel && errors.relationshipLevel
                        )}
                      >
                        <InputLabel id="relationshipLevel-select-label">
                          Relationship Level
                        </InputLabel>
                        <Select
                          name="relationshipLevel"
                          labelId="relationshipLevel-select-label"
                          id="relationshipLevel-select"
                          value={values.relationshipLevel}
                          label="Relationship Level"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("relationshipLevel", e.target.value)
                          }
                        >
                          {Object.entries(RelationshipLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`relationshipLevel-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.relationshipLevel &&
                          errors.relationshipLevel && (
                            <FormHelperText error>
                              {errors.relationshipLevel}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["alcohol"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(touched.alcohol && errors.alcohol)}
                      >
                        <InputLabel id="alcohol-select-label">
                          Alcohol
                        </InputLabel>
                        <Select
                          name="alcohol"
                          labelId="alcohol-select-label"
                          id="alcohol-select"
                          value={values.alcohol}
                          label="Alcohol"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("alcohol", e.target.value)
                          }
                        >
                          {Object.entries(AlcoholLevelEnum).map(
                            ([key, value]) => (
                              <MenuItem key={`alcohol-${key}`} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.alcohol && errors.alcohol && (
                          <FormHelperText error>
                            {errors.alcohol}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["smokeCigarettes"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.smokeCigarettes && errors.smokeCigarettes
                        )}
                      >
                        <InputLabel id="smokeCigarettes-select-label">
                          Smoke Cigarettes
                        </InputLabel>
                        <Select
                          name="smokeCigarettes"
                          labelId="smokeCigarettes-select-label"
                          id="smokeCigarettes-select"
                          value={values.smokeCigarettes}
                          label="Smoke Cigarettes"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue("smokeCigarettes", e.target.value)
                          }
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem
                              key={`smokeCigarettes-${key}`}
                              value={key}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.smokeCigarettes && errors.smokeCigarettes && (
                          <FormHelperText error>
                            {errors.smokeCigarettes}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["difficultTime"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.difficultTime && errors.difficultTime
                        )}
                      >
                        <InputLabel id="difficultTime-select-label">
                          Difficult Time
                        </InputLabel>
                        <Select
                          name="difficultTime"
                          labelId="difficultTime-select-label"
                          id="difficultTime-select"
                          value={values.difficultTime}
                          label="Difficult Time"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("difficultTime", e.target.value);
                          }}
                        >
                          {Object.entries(DifficultTimeReasonEnum).map(
                            ([key, value]) => (
                              <MenuItem
                                key={`difficultTime-${key}`}
                                value={key}
                              >
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {touched.difficultTime && errors.difficultTime && (
                          <FormHelperText error>
                            {errors.difficultTime}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Typography variant="h3" mt={8} align="center">
                    Measurement
                  </Typography>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["imageMeasurement"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        my={2}
                        fullWidth
                        error={Boolean(
                          touched.imageMeasurement && errors.imageMeasurement
                        )}
                      >
                        <InputLabel id="smokeCigarettes-select-label">
                          Image Measurement
                        </InputLabel>
                        <Select
                          name="imageMeasurement"
                          labelId="imageMeasurement-select-label"
                          id="imageMeasurement-select"
                          value={values.imageMeasurement}
                          label="Image Measurement"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setFieldValue("imageMeasurement", newValue);
                            if (newValue !== "NO") {
                              setFieldValue("waistSize", "");
                              setFieldValue("pantsSize", "");
                            }
                          }}
                        >
                          {Object.entries(BooleanEnum).map(([key, value]) => (
                            <MenuItem
                              key={`imageMeasurement-${key}`}
                              value={key}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.imageMeasurement &&
                          errors.imageMeasurement && (
                            <FormHelperText error>
                              {errors.imageMeasurement}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {values?.user?.gender === GenderEnum.Female ? (
                    <Grid mt={2} container spacing={6}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>
                          {formLabels["pantsSize"]}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          my={2}
                          fullWidth
                          error={Boolean(touched.pantsSize && errors.pantsSize)}
                        >
                          <InputLabel id="pantsSize-select-label">
                            Pants Size
                          </InputLabel>
                          <Select
                            name="pantsSize"
                            labelId="pantsSize-select-label"
                            id="pantsSize-select"
                            value={values.pantsSize}
                            label="Pants Size"
                            onBlur={handleBlur}
                            disabled={Boolean(values.imageMeasurement !== "NO")}
                            onChange={(e) =>
                              setFieldValue("pantsSize", e.target.value)
                            }
                          >
                            {Object.entries(PantsSizeEnum).map(
                              ([key, value]) => (
                                <MenuItem key={`pantsSize-${key}`} value={key}>
                                  {value}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          {touched.pantsSize && errors.pantsSize && (
                            <FormHelperText error>
                              {errors.pantsSize}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid mt={2} container spacing={6}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>
                          {formLabels["waistSize"]}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          my={2}
                          fullWidth
                          error={Boolean(touched.waistSize && errors.waistSize)}
                        >
                          <InputLabel id="waistSize-select-label">
                            Waist Size
                          </InputLabel>
                          <Select
                            name="waistSize"
                            labelId="waistSize-select-label"
                            id="waistSize-select"
                            value={values.waistSize}
                            label="Waist Size"
                            disabled={Boolean(values.imageMeasurement !== "NO")}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue("waistSize", e.target.value)
                            }
                          >
                            {Object.entries(WaistSizeEnum).map(
                              ([key, value]) => (
                                <MenuItem key={`waistSize-${key}`} value={key}>
                                  {value}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          {touched.waistSize && errors.waistSize && (
                            <FormHelperText error>
                              {errors.waistSize}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  <Box
                    component="div"
                    display="flex"
                    justifyContent="flex-end"
                    mt={6}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      mt={3}
                      mr={5}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                    >
                      {mode === "update" ? "Update" : "Save"}
                    </Button>
                  </Box>
                </form>
              )}
            </CardContent>
          )}
        </Formik>
      )}
    </>
  );
}

export default CreateEditHealthQuestionnaireForm;
